body {
  margin: 0;
  font-family: "Roboto Flex"
}

.site-header {
  background-color: #a11336; 
  padding: 20px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); 
}

nav {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.nav-list {
  list-style: none; 
  display: flex; 
  gap: 20px; 
  padding: 0;
  margin: 0;
}

.nav-list li {
  display: inline;
}

.nav-list h1 {
  font-size: 18px; 
  margin: 0; 
  font-weight: normal;
  color: #ffffff; 
}

.nav-list h1:hover {
  color: black; 
  cursor: pointer; 
}

.nav-list a {
  text-decoration: none; 
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block; 
  }

  .nav-list {
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: #a11336;
    display: none; 
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1003;
    margin-bottom: 1rem;
  }

  .nav-list.open {
    display: block; 
  }

  .nav-list li {
    margin: 10px 0;
  }
}
