.mentions-legales {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 4rem;


  
    h1 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 20px;
      color: #010101;
    }
  
    p {
      font-size: 0.85rem;
      line-height: 1.6;
      color: #000000;
      margin-bottom: 15px;
    }
  
    ul {
      list-style-type: disc;
      margin-left: 20px;
      font-size: 0.85rem;
      li {
        margin-bottom: 10px;
        font-size: 0.85rem;
      }
    }
  
    strong {
      color: #000;
    }
  }
  