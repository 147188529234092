
.logoDoctolib {
    position: fixed;
    width: 200px;
    height: auto;
    top: 80px;
    left: 30px;
    z-index: 1000;
    transition: transform 0.3s ease; 
  }
  
  .logoDoctolib:hover {
    transform: scale(1.2); 
  }
  
  @media (max-width: 768px) {
    img.logoDoctolib {
      position: static;
      width: 150px;
      margin: 1px auto; 
      display: block; 
    }
  }