.notre-personnel {
  padding: 20px;
  text-align: center;
  font-family: 'Roboto Flex', sans-serif;
  min-height: 100vh;
  overflow: hidden;
  z-index: 0;
  background-image: url('../assets/images/Salle2.webp'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}


.notre-personnel h2 {
  font-size: 2.5em;
  margin: 44px 0;
  z-index: 8;
  color: #000;
}

.doctors-container {
  display: flex;
  justify-content: center;
  gap: 550px; 
  flex-wrap: wrap;
  position: relative;
}

.doctor-card {
  background-color: rgba(244, 244, 244, 0.8); 
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  height: 900px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 130px;
  position: relative;
  z-index: 0;
}

.doctor-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 8px; 
}

.doctor-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.doctor-info {
  text-align: center;
}

.doctor-info h3 {
  font-size: 1.2em;
  color: #a31335; 
  margin-bottom: 10px;
}

.doctor-info h5 {
  font-size: 1.1em;
  color: #a31335;
  margin-top: 15px;
  text-align: left;
}

.doctor-info ul {
  list-style-type: disc;
  margin-left: 20px;
  text-align: left;
}

.doctor-info ul li {
  font-size: 0.95em;
  color: #333;
  margin-bottom: 8px;
}

.doctor-info p {
  font-size: 1em;
  color: #333;
  line-height: 1.6;
  text-align: left;
}

.consult-image-container {
  position: absolute;
  top: 70%; 
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.consult-image {
  width: 500px; 
  height: 500px; 
  border-radius: 50%; 
  object-fit: cover;  
  border: 3px solid #a31335;
  top: 30%;
  left: 40%;  
}

.circle-custom {
  width: 810px;
  height: 810px;
  border-radius: 70%;
  background-color: #a31335; 
  background-size: 740px 740px; 
  background-position: center 5mm;
  background-repeat: no-repeat; 
  position: absolute;
  top: 0%;
  left: 24%;
  z-index: -1;
}

@media (max-width: 1440px) {

  .consult-image-container {
    position: absolute;
    top: 80%; 
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .consult-image {
    width: 300px; 
    height: 300px; 
    top: 30%;
    left: 40%;  
  }
  
}
@media (min-width: 1263px) and (max-width: 1440px) {
  .doctors-container {
    gap: 300px;
  }

  .doctor-card {
    width: 250px;
    height: auto;
    margin-bottom: 50px;
  }

  .consult-image {
    width: 100%;
    max-width: 50%;
    height: auto;
    top: 9%;
    left: 75%;
  }

  .circle-custom {
    width: 600px;
    height: 600px;
    top: 10%;
    left: 20%;
  }
}

@media (max-width: 1272px) {
  .doctors-container {
    gap: 300px;
  }

  .doctor-card {
    width: 250px;
    height: auto;
    margin-bottom: 50px;
  }

  .consult-image {
    width: 100%;
    max-width: 50%;
    height: auto;
    top: 9%;
    left: 75%;
  }

  .circle-custom {
    width: 600px;
    height: 600px;
    top: 10%;
    left: 20%;
  }
}

@media (max-width: 939px) {
  .doctors-container {
    gap: 20px;
  }

  .doctor-card {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 40px;
  }

  .consult-image-container {
    position: absolute;
    top: 20%; 
    left: 89%;
    transform: translate(-50%, -50%);
  }

  .consult-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    top: 50%;
    left: 5%;
  }
}

@media (max-width: 768px) {
  .doctors-container {
    gap: 20px;
  }

  .doctor-card {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 40px;
  }

  .circle-custom {
    width: 500px;
    height: 500px;
    top: 20%;
    left: 10%;
  }
}


@media (max-width: 480px) {
  .notre-personnel h2 {
    font-size: 1.8em;
  }

  .doctor-info h3 {
    font-size: 1.1em;
  }

  .doctor-info h5 {
    font-size: 1em;
  }

  .doctor-info ul li {
    font-size: 0.85em;
  }

  .consult-image {
    width: 90%;
    max-width: 250px;
    height: auto;
    top: 60%;
    left: 0%;
  }

  .circle-custom {
    width: 300px;
    height: 300px;
    top: 30%;
    left: 10%;
  }
}
