.acces-container {
  padding: 40px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #666;
  }

  .leaflet-container {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
    margin-bottom: 3rem;
  }

  .image-with-caption {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    display: inline-block;

    .captioned-image {
      width: 50%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .caption {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 5px 10px;
      font-size: 1.2em;
      border-radius: 8px;
      text-align: center;
    }
  }
}
