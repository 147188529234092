.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto Flex', sans-serif;
  
    h1 {
      text-align: center;
      color: #9d1738;
      margin-top: 1px;
      margin-bottom: 20px;
      font-size: 2.5rem;
    }
  
    .faq-list {
      .faq-item {
        background-color: #f7f7f7;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 10px 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        
        .faq-question {
          font-size: 1.2rem;
          color: #333;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          &:hover {
            color: #9d1738;
          }
        }
  
        .faq-icon {
          font-size: 1.5rem;
          color: #9d1738;
        }
  
        .faq-answer {
          margin-top: 10px;
          font-size: 1rem;
          color: #555;
          line-height: 1.6;
        }
      }
    }
  }
  @media (max-width: 750px) {
    .faq-container {
      padding: 10px;
      margin-bottom: 30px;
      h1 {
        font-size: 1rem;
        margin-bottom: 20px; 
      }
    }
  
    .faq-list {
      .faq-item {
        margin-top: 10px;
        padding: 8px 15px; 
        margin-bottom: 105px;
        font-size: 0.9rem; 
        
        .faq-question {
          font-size: 1rem; 
        }
  
        .faq-answer {
          font-size: 0.9rem; 
        }
      }
    }
  }
  