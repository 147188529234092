
.contact-container {
    display: flex;
    justify-content: space-around;
    padding: 40px;
    background-color: #f7f7f7; 
    margin-bottom: 3rem;
  }

  
  form {
    width: 45%;
    background-color: #ffffff; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333333;
    font-family: 'Arial', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="checkbox"],
  textarea {
    width: 95%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-family: 'Arial', sans-serif;
    font-size: 1rem;
    background-color: #fafafa;

  }
  
  input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }

  textarea {
    height: 100px;
    resize: none;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }

  button[type="submit"]:hover {
    background-color: #004494;
  }
  
  input,
  textarea {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      padding: 20px;
    }
  
    form {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  