.les-locaux {
  position: relative; 
  padding: 40px;
  background-image: url('../assets/images/Maisonmedical.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1; 
  }


  h2, p, .location-cards, .gallery {
    position: relative;
    z-index: 2; 
  }

  h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #fff; 
    text-align: center;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #ddd;
    text-align: center; 
  }

  .location-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;

    .card {
      background-color: rgba(255, 255, 255, 0.9); 
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      width: 300px;
      text-align: left;

      h3 {
        font-size: 1.5em;
        margin-bottom: 10px;
        text-align: center;
        color: #007bff;
      }

      p {
        font-size: 1.1em;
        color: #666;
      }
    }
  }

  .gallery {
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 3rem; 

    @media (max-width: 737px) { 
      height: 300px; 
      overflow: hidden;

      .image-card {
        position: absolute; 
        width: 100%;
        height: 100%;
        transition: opacity 0.5s ease-in-out;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .image-card.active {
        opacity: 1; 
      }

      .image-card.hidden {
        opacity: 0; 
      }
    }
    
    @media (min-width: 738px) {
      height: auto; 
      display: flex; 
      flex-wrap: wrap;
      gap: 20px;

      .image-card {
        position: relative; 
        width: 200px; 
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }


  .image-card {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    .close {
      position: absolute;
      top: 20px;
      right: 30px;
      font-size: 40px;
      color: #fff;
      cursor: pointer;
    }

    .modal-content {
      max-width: 50%;
      max-height: 80%;
      border-radius: 8px;
      object-fit: contain;
    }

    .prev, .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      cursor: pointer;
      padding: 10px;
    }

    .prev {
      left: 30px;
    }

    .next {
      right: 30px;
    }
  }


  @media (max-width: 465px) {
    .location-cards {
      flex-direction: column; 
      align-items: center; 
    }
  
    .card {
      width: 80%; 
      max-width: 250px; 
      font-size: 1em; 
    }
  
    .card h3 {
      font-size: 1.3em;
    }
  
    .card p {
      font-size: 0.9em;
    }
  }
  
}
